import Axios from 'axios';
import Tips from "../components/Tips/Tips";
import Cookie from 'react-cookies';
import I18N, {i18n} from "../components/Util/I18nUtils";
import {clearLocalStorage} from "./Auth";
// import qs from 'qs'

/**
 * 根据环境变量区分接口的默认地址
 */
/* switch(process.env.NODE_ENV){
    case "production":
        axios.defaults.baseURL='';//生产环境
        break;
    case "test":
        axios.defaults.baseURL="";//测试url
        break;
    default:
        axios.defaults.baseURL="http://192.168.0.153:3000/";//开发环境下的URL
        break;

} */
let axios = Axios.create({
  timeout: 30000,
  // baseURL: '/saas/',
  withCredentials: false,
});

/**
 * 设置超时时间和跨域是否允许携带凭证
 */
axios.defaults.timeout = 10000;//超时时间
axios.defaults.withCredentials = true;//允许跨域

/**
 * 设置请求传递数据的格式
 * x-www-form-urlencoded
 */
/* axios.defaults.headers['Content-Type']='application/x-www-form-urlencoded';
axios.defaults.transformRequest=data=>qs.stringify(data); */

/**
 * 请求拦截器
 * TOKEN校验（JWT）:接收服务器返回的token 存储到本地存储中，
 * 每一次向服务器发送请求，我们应该携带token
 */
axios.interceptors.request.use(
  (config) => {
    //携带username
    let token = Cookie.load('token');
    token && (config.headers.Authorization = token);
    setLangParam(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
const setLangParam = (config) => {
  let url = config.url;
  // 存在该参数则,不拼接
  if(url.indexOf("?lang=") > -1 || url.indexOf("&lang=") > -1) {
    return config;
  }

  // 不存在则拼接
  if(url.match(/\?.*?$/g)) {
    if(!url.endsWith("&")) {
      url += "&";
    }
  }
  else {
    url += "?";
  }

  const langParam = `lang=${localStorage.getItem("lang") || 'zh_CN'}`;
  url += langParam;

  config.url = url;
};

/**
 * 响应拦截器
 */

/* axios.defaults.validateStatus=status=>{
 //自定义响应成功的HTTP状态码
    return /^(2|3)\d{2}$/.test(status);

}; */
axios.interceptors.response.use(
  (response) => {
    let token = Cookie.load('token');
    token && (response.headers.Authorization = token);
    return response; //返回响应的数据
  },
  (error) => {
    let { response } = error;
    if (response) {
      switch (response.status) {
        case 400:
          throw error;
        case 401:
          localStorage.clear();
          Cookie.remove('token', { path: '/' });
          window.location.href = response.headers.saas_index;
          break;
        case 403:
          break; //服务器拒绝执行
        case 404:
          break;
        case 302:
          window.location.href = response.headers.location
          break;
        default:
          Tips.error(`${i18n(I18N.SAAS_SYS_ERROR)}[${response.statusText}]`);
      }
    } else {
      if (error.message.includes('timeout')) {
        //超时处理
        Tips.error(i18n(I18N.SAAS_SYS_REQUEST_TIMEOUT));
        return;
      }
      return Promise.resolve(error); //服务器问题
    }
  },
);

export default axios;
